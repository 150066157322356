import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import nodeStyle from "../../css/node.module.css";
import {Helmet} from "react-helmet";

/**
 * 1    =   Walk input directory
 * 2    =   Walk repository directory
 * 4    =   Walk Cassandra index
 * 8    =   1 on 1 insert
 * 16   =   Filename exists
 * 32   =   Override file
 * 64   =   Skip file
 * 128  =   100% vector match
 * 256  =   replace with higher resolution
 *
 * @param message
 */
const ImageFunctions = () => {
    const currentUser = AuthService.getCurrentUser();
    const [graphBatchRw, setGraphBatchRw] = useState("");

    const [mynumber, setMynumber] = useState(-1);
    const [myPurgednumber, setMyPurgedNumber] = useState(-1);
    const [myrequested, setRequested] = useState("");
    const [nodes, setNodes] = useState([]);
    const [purgedMessage, setPurgedMessage] = useState("")
    const [message, setMessage] = useState("")

    // getAndMoveImage

    const imageFunction = (requestedFunction) => {
        setMessage("Start " + requestedFunction)
        // e.preventDefault();
        UserService.imageFunctions(requestedFunction).then(
            (response) => {
                console.log(response.data)
                setNodes(response.data.returnRecord);
                setMynumber(response.data.numberOfRecords)
                // setKey(response.data.requested);
                setRequested(response.data.searchString)
                setMessage(response.data.accompanyingText)
                console.log("searchString = " + response.data.searchString)
                console.log("status = " + response.data.status)
                console.log("timeStamp = " + response.data.timeStamp)
                // const data = response.data;
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setContent2(_content);
            }
        );
    }


    return (
        <div className="headDiv">
            <Helmet>
                <title>Image Functions</title>
            </Helmet>
            <table className={nodeStyle.datatable}>
                <tbody>
                <tr>
                    <td><br/><h5>Import images from importdirectory</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary" onClick={() => imageFunction(1+8)}>Start
                        </button>
                    </td>
                </tr>
                <tr>
                    <td><br/><h5>Import images from importdirectory replace existing</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary" onClick={() => imageFunction(1+256)}>Start
                        </button>
                    </td>
                </tr>
                {/*<tr>*/}
                {/*    <td><br/><h5>Remove images from trash</h5><br/></td>*/}
                {/*    <td>*/}
                {/*        <button className="btn btn-secondary"*/}
                {/*                onClick={() => imageFunction(-1)}>Start*/}
                {/*        </button>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                <tr>
                    <td><br/><h5>Check image repository</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary"
                                onClick={() => imageFunction(2)}>Start
                        </button>
                    </td>
                </tr>
                <tr>
                    <td><br/><h5>Remove vector entries for vectors without image</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary"
                                onClick={() => imageFunction(4)}>Start
                        </button>
                    </td>
                </tr>
                <tr>
                    <td><br/><h5>Remove contentUrl from nodes without images</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary"
                                onClick={() => imageFunction(-1)}>Start
                        </button>
                    </td>
                </tr>
                <tr>
                    <td><br/><h5>Add vector for images without vector</h5><br/></td>
                    <td>
                        <button className="btn btn-secondary"
                                onClick={() => imageFunction(2)}>Start
                        </button>
                    </td>
                </tr>

                {message &&
                    <tr>
                        <td><h5>{message}</h5></td>
                        <td>{(myPurgednumber >= 0) && <>{purgedMessage}:&nbsp;{myPurgednumber}</>}</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    );
}
export default ImageFunctions;
